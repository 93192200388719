.icon-wrap {
  margin:0;
  .sphere1 {
    border-radius: 50%;
    background-color: #22654e;
    width:40px;
    height:40px;
  }
  .sphere2 {
    border-radius: 50%;
    background-color: rgba(199,252,181,.6);
    backdrop-filter: blur(5px);
    width:60px;
    height:60px;
    margin-top:-30px;
    margin-left:15px;
  }
}
