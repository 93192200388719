.icon-button {
  text-decoration: none !important;
  background: #fff;
  border: 2px solid #d2f3cd;
  border-radius: 30px;
  padding: 15px 10px 15px 10px;
  line-height: 1;
  cursor: pointer;
  .icon-holder {
    width: 50px;
    color: #22654e;
  }
  .copy-holder {
    color: #0c4733;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
  }
  .arrow-holder {
    width: 40px;
    color:#22654e;
    img {
      width: 12px;
      position: relative;
      top: 0px;
    }
  }
}
