.about {
  padding-bottom:100px;
  h3 {
    color:#0c4733;
    font-size:40px;
    margin:15px 0 0 0;
  }
  .intro {
    p {
      font-size:22px;
      margin:0 0 15px 0;
      font-weight: 500;
      color:#1c4b3c;
      line-height: 1.7;
    }
  }
  .features {
    margin-top:100px;
    .item {
      background-color: #dde8cf;
      padding:30px 40px 30px 40px;
      border-radius: 8px;
      h4 {
        color:#22654e;
        font-size: 27px;
        margin:0 0 15px 0;
      }
      p {
        color:#1c4b3c;
        font-size: 20px;
      }
      img {
        display: block;
        max-height: 70px;
        margin:0 0 20px 0;
      }
    }
  }
  .revenue {
    margin:85px 0 0 0;
    h3 {
      color:#0c4733;
      font-size:40px;
      margin:0;
    }
    p {
      font-size:22px;
      font-weight: 500;
      color:#1c4b3c;
    }
    .item {
      margin-bottom: 85px;
      h4 {
        color:#22654e;
        font-size: 27px;
        margin:15px 0 15px 0;
        line-height: 1.3;
      }
      p {
        color:#1c4b3c;
        font-size: 20px;
      }
    }
  }
}
