html,
body {
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  overflow-x: hidden;
}

.logo-placeholder {
  img {
    max-height: 30px;
    display: block;
  }
}

.naked-button {
  background: transparent;
  border:none;
  padding:0;
}

.front-page-background-1 {
  position: absolute;
  left:-25%;
  width:60%;
  z-index:-1;
}

.credit-market-background-1 {
  position: absolute;
  left:-15%;
  width:50%;
  z-index:-1;
  top:115%;
}

.credit-market-background-2 {
  position: absolute;
  right:-10%;
  top:20%;
  width:50%;
  z-index:-1;
}