.team {
  img {
    display:block;
    border-radius: 12px;
    cursor: pointer;
  }
  h4 {
    color:#0c4733;
    font-size:23px;
    margin:10px 0 0 0;
  }
  .position {
    font-size:18px;
    color:#1f3b21;
    line-height: 1.3;
  }
}

.bio {
  padding:10px 20px 20px 20px;
  h3 {
    color:#0c4733;
    font-size:22px;
    font-weight: 700;
  }
  p {
    font-size:16px;
    color:#1f3b21;
  }
}