.main {
  padding-bottom:100px;
  ul {
    margin:15px 0 50px 0;
    padding:0;
    list-style: none;
    font-weight: 800;
    li {
      h3 {
        color:#0c4733;
        font-size:60px;
        margin:0;
      }
    }
  }
  p {
    font-size:22px;
    margin:0 0 15px 0;
    font-weight: 500;
    color:#1c4b3c;
    line-height: 1.7;
  }
  img {
    max-width: 80%;
  }
  button {
    margin-top:15px;
  }
  .logos {
    
  }
  h4 {
    font-size:22px;
    font-weight: 700;
    color:#1c4b3c;
    margin:30px 0 30px 0;
  }
}

@media (max-width: 768px) {
  .main {
    ul {
      margin:15px 0 50px 0;
      li {
        h3 {
          color:#0c4733;
          font-size:40px;
        }
      }
    }
  }
}