.footer {
  margin: 50px 0 50px 0;
  color:#fff;
  .legal {
    a {
      color:#fff;
    }
  }
  .social {
    img {
      height:25px;
      opacity: 0.7;
    }
  }

}