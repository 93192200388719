.subpage {
  padding-bottom:100px;
  margin-top: 180px;
  h3 {
    color:#0c4733;
    font-size:40px;
    margin:15px 0 15px 0;
    font-weight: 700;
  }
  h5 {
    color:#0c4733;
    font-size:30px;
    margin:15px 0 15px 0;
  }
  h6 {
    margin-bottom: 15px;
  }
  p {
    font-size:15px;
    font-weight: 500;
    color:#1f3b21;
    line-height: 1.7;
  }
  .ps-table {
    background:#DDE8CF;
    border-radius: 6px;
    th {
      border-bottom:1px solid #9db57f;
      font-weight:600;
    }
    td {
      border-bottom:1px solid #9db57f;
    }
  }
}

.membership {
  .intro {
    font-size:22px;
    color:#1c4b3c;
  }
  .panel {
    padding:40px;
    background-color: #1c4b3c;
    border-radius: 8px;
    p {
      font-size:18px;
      color:#fff;
      opacity: 0.9;
    }
    h4 {
      font-size:27px;
      color:#fff;
      font-weight: 700;
    }
    .divider {
      border-color:rgba(255,255,255,0.2);
    }
    ul {
      li {
        color:#fff;
        opacity: 0.9;
        font-size:18px;
        margin:0 0 10px 0;
      }
    }
  }
}

.standard {
  .download {
    background-color: #DDE8CF;
    border-radius: 8px;
    padding:15px;
    cursor: pointer;
    img {
      max-height: 80px;
      margin-right:15px;
    }
    h4 {
      color:#22654e;
      font-size:27px;
      font-weight: 700;
    }
    p {
      color:#1c4b3c;
      font-size:17px;
    }
  }
  .media {
    h4 {
      color:#22654e;
      font-size:27px;
      font-weight: 700;
    }
    p {
      color:#1c4b3c;
      font-size:17px;
    }
  }
}

.vvb {
  h4 {
    color:#22654e;
    font-size:27px;
    font-weight: 700;
  }
  p {
    color:#1c4b3c;
    font-size:17px;
  }
}