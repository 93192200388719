.main {
  background: url(./../../Landing/assets/hero.jpg) no-repeat center center scroll;
}

.about {
  background: url(./../../Landing/assets/about.jpg) no-repeat center center scroll;
}

.carbon-market {
  background: url(./../../Landing/assets/carbon-market.jpg) no-repeat center center scroll;
}

.background {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  margin-bottom: 100px;
}

.main-header {
  position: relative;
  height:100vh;
  &:after {
    content: " ";
    position:absolute;
    width:100%;
    height:5px;
    z-index: 1;
    bottom: 0;
    background: #8642fe;
    background: linear-gradient(90deg, #006A4E 0%, #5eedf7 50%, #4FFFB0 100%);
    background-size: 180% 180%;
    animation: gradient-animation 5s ease infinite
  }
  .video-background {
    height:100%;
    width:100%;
    z-index: 0;
    position: absolute;
    .video {
      top:0;
      left:0;
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1000;
     overflow: hidden
    }
  }
  .tint {
    position: absolute;
    top:0;
    width:100%;
    height:100%;
    background: rgba(18,33,17,0.3);
    z-index:1;
  }
  .slogan {
    z-index:2;
    text-align: center;
    color:#fff;
    .line1 {
      margin: 0;
      font-weight: 700;
      font-size: 50px;
      text-transform: uppercase;
      background: linear-gradient(90deg, #c1dfc4 0%,  #deecdd 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .line2 {
      margin: 0 0 20px 0;
      font-weight: 700;
      font-size: 80px;
      text-transform: uppercase;
      background: linear-gradient(90deg, #deecdd 0%, #c1dfc4 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .inner-wrapper {
    max-height: calc(100% - 100px);
  }
  .sub-slogan {
    color:#fff;
  }
  .invest-button {
    background: #f48236 !important;
    color:#fff;
    font-size:20px;
    box-shadow: 0 0 0 5px rgba(244,130,54,0.2);
    border-radius: 35px;
    padding:15px 40px 15px 40px;
    text-transform: uppercase;
  }
  button {
    color: #fff;
  }
  .play-button {
    background:transparent;
    svg {
      font-size: 100px;
      opacity: 0.6;
    }
  }
}

.about-header, .carbon-market-header {
  .tint {
    position: absolute;
    top:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.5);
    z-index:1;
  }
  .slogan {
    padding-top:150px;
    padding-bottom:150px;
    z-index:2;
    position: relative;
    h1 {
      font-weight: 700;
      font-size: 65px;
      line-height:1.3;
      color:#fff;
    }
    p {
      color:#fff;
      font-size:22px;
      margin-top:50px;
    }
  }
}

@media (max-width: 991px) {
  .main-header {
    .slogan {
      .line1 {
        font-size: 1.2rem;
        letter-spacing: -0.5px;
      }
      .line2 {
        font-size: 2rem;
        letter-spacing: -0.5px;
      }
    }
    .play-button {
      svg {
        font-size: 70px;
      }
    }
  }
}

@media (max-width: 768px) {
  .main-header {
    .slogan {
      .line1 {
        font-size: 1.4rem;
      }
      .line2 {
        font-size: 2.2rem;
      }
    }
    .play-button {
      svg {
        font-size: 90px;
      }
    }
    .sub-slogan {
      p {
        font-size:0.9rem
      }
    }
  }
  .about-header, .carbon-market-header {
    .slogan {
      h1 {
        font-weight: 700;
        font-size:50px;
        line-height:1.3;
        color:#fff;
      }
      p {
        color:#fff;
        font-size:22px;
        margin-top:50px;
      }
    }
  }
}

.modal-header {
  padding-left:23px;
  margin-top:10px;
  button {
    position: relative;
    right:10px;
  }
}
.modal-wrapper {
  background-color: #fff;
  border-radius: 4px;
  height:100%;
}
.fullscreen {
  height:100%;
  div {
    width:100%;
    height:60vh;
    iframe {
      height:100%;
      width:100%;
      display: block;
    }
  }
}
@media (max-width: 576px) {
  
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}