.carbon-market {
  padding-bottom:100px;
  h3 {
    color:#0c4733;
    font-size:40px;
    margin:15px 0 15px 0;
  }
  p {
    font-size:22px;
    font-weight: 500;
    color:#1c4b3c;
    line-height: 1.7;
  }
  .offset {
    margin-top:200px;
  }
}

.bleed {
  background-color: #dde8cf;
  padding-top:150px;
  padding-bottom: 100px;
  h3 {
    font-size:60px;
  }
  p {
    margin-top:30px;
    margin-bottom:35px;
  }
  ul {
    padding:0;
    margin:40px 0 0 0;
    list-style: none;
    li {
      margin:0 0 30px 0;
      p {
        margin:-2px 0 5px 0;
        color:#458570;
        font-size: 18px;
        line-height: 1.5;
      }
      h4 {
        margin:0;
        color:#22654e;
        font-size: 30px;
      }
      svg {
        fill:#22654e;
        font-size: 45px;
        margin:0;
        display:block;
        margin-right:15px;
      }
    }
  }
}

@media (max-width: 768px) {
  .bleed {
    h3 {
      font-size:40px;
    }
  }
}